import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { decodeJwt, getTokenValue, removeTokenValue } from './utils/common.utils';
import Loader from './components/loader';

const ProtectedRoute = ({ component: Component }) => {
  const history = useHistory();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const accessToken = getTokenValue();
    if (accessToken) {
      const tokenData = decodeJwt(accessToken);
      if (Number(tokenData.exp) > Math.floor(Date.now() / 1000)) {
        setIsLoggedIn(true);
      } else {
        removeTokenValue();
        history.replace('/');
      }
    } else {
      removeTokenValue();
      history.replace('/');
    }
  }, []);

  if (isLoggedIn) {
    return <Component />;
  }
  return <Loader />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired,
};

export default ProtectedRoute;
